"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _useThemeContext = require("@oberoninternal/travelbase-ds/hooks/useThemeContext");
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _TenantContext = require("../context/TenantContext");
var _Pagination = _interopRequireWildcard(require("./designsystem/Pagination"));
var _WideGrid = _interopRequireWildcard(require("./designsystem/WideGrid"));
var _ListPaginationLink = _interopRequireDefault(require("./ListPaginationLink"));
var _SearchAlternativeDates = _interopRequireDefault(require("./SearchAlternativeDates"));
var _SearchHit = _interopRequireDefault(require("./SearchHit"));
var _SearchSpecial = _interopRequireWildcard(require("./SearchSpecial"));
var _Stack = _interopRequireDefault(require("./Stack"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const useImageSizes = () => {
  const {
    mediaQueries
  } = (0, _useThemeContext.useThemeContext)();
  return `(min-width: ${mediaQueries.xxxl}) 25vw, (min-width: ${mediaQueries.xl}) 447px, (min-width: ${mediaQueries.l}) 30vw, (min-width: ${mediaQueries.m}) 447px, (min-width: ${mediaQueries.s}) 50vw, 100vw`;
};
const SearchList = ({
  isLoading,
  data,
  currentPageIndex,
  pageCount,
  unitParams,
  activeSpecial,
  lastSearchParams,
  filtersActive,
  removeActiveFilters,
  searchType,
  onHitClick
}) => {
  const hits = data ?? [];
  const intl = (0, _reactIntl.useIntl)();
  const sizes = useImageSizes();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    LowSearchListing
  } = brandConfig.customContent ?? {};
  const router = (0, _router.useRouter)();
  return __jsx(Container, null, activeSpecial && __jsx("section", null, typeof activeSpecial === 'string' ? __jsx(_SearchSpecial.default, (0, _SearchSpecial.getPropsForTripType)(activeSpecial, intl)) : __jsx(_SearchSpecial.default, activeSpecial.special)), isLoading && __jsx(_WideGrid.default, {
    widths: [100, 100 / 2, 33.3]
  }, new Array(_Pagination.PAGE_MAX).fill(null).map((_, i) => __jsx(_WideGrid.Cell, {
    key: i
  }, __jsx(_SearchHit.default, {
    loading: true
  })))), !isLoading && hits.length > 0 && __jsx(_WideGrid.default, {
    widths: [100, 100 / 2, 33.3]
  }, hits.map(hit => __jsx(_WideGrid.Cell, {
    key: hit.rentalUnit.id
  }, __jsx(_SearchHit.default, {
    rentalUnit: hit.rentalUnit,
    trips: hit.trips,
    activeSpecial: typeof activeSpecial !== 'string' ? activeSpecial?.special.id : undefined,
    unitParams: _objectSpread(_objectSpread({}, unitParams), {}, {
      searched: router.asPath.split('?')[1]
    }),
    sizes: sizes,
    onHitClick: onHitClick
  })))), !isLoading && hits.length === 0 && __jsx("section", null, __jsx(_Stack.default, {
    spacing: 5
  }, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "2lq5C3",
    defaultMessage: [{
      "type": 0,
      "value": "Geen accommodaties gevonden"
    }]
  })), filtersActive && __jsx(_Button.default, {
    style: {
      alignSelf: 'flex-start'
    },
    variant: "outline",
    onClick: removeActiveFilters,
    type: "button"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "+PgRNi",
    defaultMessage: [{
      "type": 0,
      "value": "Filters verwijderen"
    }]
  })))), __jsx(_Pagination.default, {
    currentPageIndex: currentPageIndex,
    pagesCount: pageCount,
    LinkComponent: _ListPaginationLink.default
  }), !isLoading && pageCount <= 1 && searchType === 'static' && lastSearchParams?.date && lastSearchParams?.duration && __jsx(_SearchAlternativeDates.default, {
    lastSearchParams: lastSearchParams
  }), LowSearchListing && __jsx(LowSearchListing, null));
};
const Container = _styledComponents.default.div.withConfig({
  displayName: "SearchList__Container",
  componentId: "sc-5kznkj-0"
})(["width:100%;margin-top:", ";@media screen and (min-width:", "){margin-top:0;}& > * + *{margin-top:6.4rem;}padding-bottom:3.2rem;"], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.mediaQueries.s);
var _default = exports.default = SearchList;